//////////////// ALL CSS IMPORTS FOR NOW
import { ChakraProvider, useColorMode } from '@chakra-ui/react';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'focus-visible/dist/focus-visible';
import 'mapbox-gl/dist/mapbox-gl.css';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import Script from 'next/script';
import '@fontsource/crimson-pro/700.css';
//////////////// END ALL CSS IMPORTS FOR NOW
import { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import env from '../env';
import { StoreProvider, useStore } from '../hooks';
import SEO from '../next-seo-config';
import '../styles/css/Draw.css';
import '../styles/css/MarkerPopup.css';
import '../styles/css/Swiper.css';
import theme from '../styles/theme';
import { queryClient } from '../util/api/clients/queryClient';
import * as gtag from '../util/gtag';
import tracker from '../util/open-replay-tracker';
import Head from 'next/head';
import { observer } from 'mobx-react';

const ColorModeTracker = observer(({ children }) => {
  const store = useStore();
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    store.setColorMode(colorMode);
  }, [colorMode]);

  useEffect(() => {
    if (store.colorMode !== colorMode) {
      toggleColorMode();
    }
  }, [store.colorMode]);
  return children;
});

function WanderAppComponent({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (window?.location?.host !== 'dev.wander-app.com') {
      console.log('Not tracking...');
      return;
    }
    tracker
      .start()
      .then(() => {
        console.log('Open Replay is tracking...');
      })
      .catch((err) => {
        console.error('Open Replay failed', err);
      });
  }, []);

  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Script
        id="hotjar-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2946685,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <StoreProvider initialState={pageProps.initialState}>
          <ErrorBoundary source="_app">
            <DefaultSeo
              {...SEO(
                pageProps?.data?.map?.name,
                pageProps?.data?.map?.tagline,
                pageProps?.data?.map?.cover_photo_url,
              )}
            />
            <ChakraProvider resetCSS theme={theme}>
              <ColorModeTracker>
                <Component {...pageProps} />
              </ColorModeTracker>
            </ChakraProvider>
          </ErrorBoundary>
        </StoreProvider>
      </QueryClientProvider>
    </>
  );
}

export default WanderAppComponent;
