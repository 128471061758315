export enum StartEndMarkerOptionsEnum {
  MARKER = 'MARKER',
  ANIMATION = 'ANIMATION',
}

export type StartEndMarkerOptions = Array<StartEndMarkerOptionsEnum>;
export interface IAddPath {
  start_elevation?: number;
  end_elevation?: number;
  elevation_gain_feet?: number;
  length?: number;
  hours?: Record<string, unknown>;
  photos?: string[];
  highlight_medias?: string[];
  coordinates: number[][] | number[][][];
  name: string;
  description?: string;
  category_id: string;
  metadata?: Record<string, unknown>;
  default_pitch?: number;
  default_bearing?: number;
  item_ids?: Array<string>;
  start_point?: [[number, number]];
  end_point?: [[number, number]];
  start_end_marker_options?: StartEndMarkerOptions;
  updated_by?: string;
  sort_order?: number;
  website?: string;
}
